import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {usePrevious} from "@pg-mono/hooks";
import {RequestState} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {fetchApplicationRelatedData, initializeApplicationRelatedDataAction} from "../../application/actions/fetch_application_related_data";
import {IApplicationData} from "../../application/reducers/application_reducer";

interface IUseLeadDataParams {
    storeHash: string;
    vendorId: number;
    offerId?: number;
    propertyId?: number;
    skipFetch?: boolean;
}

export function useLeadData(params: IUseLeadDataParams) {
    const {vendorId, offerId, propertyId, skipFetch} = params;

    const dispatch = useDispatch();

    const ids = {vendorId, offerId, propertyId};
    const storeHash = getStoreHash(params.storeHash, ids);
    const leadDataRequest = useSelector((store: IRPStore) => store.application[storeHash]?.leadDataRequest);
    const leadData = useSelector((store: IRPStore) => store.application[storeHash]);

    const prevVendorId = usePrevious(vendorId, vendorId);
    const prevOfferId = usePrevious(offerId, offerId);
    const prevPropertyId = usePrevious(propertyId, propertyId);

    useEffect(() => {
        if (!leadData) {
            dispatch(initializeApplicationRelatedDataAction(storeHash));
        }
    }, [leadData]);

    useEffect(() => {
        if (!skipFetch && leadData && leadDataRequest === RequestState.None) {
            dispatch(fetchApplicationRelatedData(storeHash, vendorId, offerId, propertyId));
        }
    }, [skipFetch, leadData, leadDataRequest]);

    useEffect(() => {
        const isLeadDataLoaded = leadData && (leadDataRequest === RequestState.Success || leadDataRequest === RequestState.Error);

        if (leadData && isLeadDataLoaded && isAnyIdChanged(ids, {prevOfferId, prevPropertyId, prevVendorId})) {
            dispatch(fetchApplicationRelatedData(storeHash, vendorId, offerId, propertyId));
        }
    }, [leadData, leadDataRequest, vendorId, offerId, propertyId]);

    const dataLoaded = leadData && leadData.leadDataRequest === RequestState.Success;

    return dataLoaded ? (leadData as IApplicationData) : null;
}

function isAnyIdChanged(
    ids: {vendorId: number; offerId?: number; propertyId?: number},
    prevIds: {prevVendorId: number; prevOfferId?: number; prevPropertyId?: number}
) {
    const {vendorId, offerId, propertyId} = ids;

    return prevIds.prevOfferId !== offerId || prevIds.prevPropertyId !== propertyId || prevIds.prevVendorId !== vendorId;
}

function getStoreHash(storeHash: string, ids: {vendorId: number; offerId?: number; propertyId?: number}) {
    const offerText = ids.offerId ? `o${ids.offerId}` : "";
    const propertyText = ids.propertyId ? `p${ids.propertyId}` : "";

    return `v${ids.vendorId}${offerText}${propertyText}`;
}
